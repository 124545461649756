<template>
  <div class="list_container">
    <m-nav
      ref="nav"
      :page="'List'"
      :url="searchValue"
      @onSearch="goSearchList"
      @onNavType="goTypeList"
      @onType="goType"
    ></m-nav>
    <list-template
      :type="'one'"
      :data="dataList"
      :searchValue="searchValue"
    ></list-template>
    <div class="page_box" v-if="total && total > 0">
      <div
        class="first f_14"
        :class="{ active_white: currentPage == 1 }"
        @click="goFirst"
      >
        « First
      </div>
      <van-pagination
        v-model="currentPage"
        :total-items="total"
        :items-per-page="pageNum"
        :show-page-size="pageSize"
        class="page_class f_14"
        @change="changePage(currentPage)"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
      </van-pagination>
      <div
        class="end f_14"
        :class="{ active_white: currentPage == endPage }"
        @click="goEnd"
      >
        end »
      </div>
    </div>
    <new-footer-m></new-footer-m>
  </div>
</template>

<script>
import {
  fictionHot,
  fictionLatest,
  fictionCompleted,
  fictionSearch,
} from "@/api/index.js";
import MNav from "@/components/M/MNav.vue";
import ListTemplate from "@/components/M/ListTemplate.vue";
import NewFooterM from "@/components/M/NewFooterM.vue";
export default {
  components: { MNav, ListTemplate, NewFooterM },
  name: "List",
  inject: ["reload"],
  metaInfo() {
    return {
      title: this.metaTitle + " | Webnovelsite",
      meta: [
        {
          name: "title",
          content: this.metaTitle + " | Webnovelsite",
        },
        {
          name: "description",
          content:
            "Webnovelsite is the foremost English  publisher of Chinese, Korean, Japanese and  English  webnovels and light novels. All thousands of novels are free and updated daily.",
        },
        {
          name: "keywords",
          content:
            "best webnovel, free webnovels, free books, novel updates, webnovel site, best light novels, webnovelsite",
        },
      ],
    };
  },
  data() {
    return {
      dataList: [],
      searchValue: "", //搜索
      currentPage: 1, //当前页
      endPage: null, //最后一页
      pageSize: 7, //page页面数码展示
      total: null, //总数量
      pageNum: 20, //一页显示数量
      typeKey: null, //类型关键id
      searchKey: null, //搜索关键字
      navListKey: null, //导航条最左侧列表类型id
      type: null, //类型 1搜书名 2搜小说类型 3搜索作者 4小说是否完成 5固定类型
      metaTitle: "", //标题头
    };
  },
  watch: {
    "$route.path": {
      handler(toPath, fromPath) {
        if (toPath == "/search") {
          this.type = 1;
        } else if (toPath.indexOf("/genre") !== -1) {
          this.type = 2;
        } else if (toPath.indexOf("/author") !== -1) {
          this.type = 3;
        } else if (toPath.indexOf("/status") !== -1) {
          this.type = 4;
        } else if (toPath.indexOf("/list") !== -1) {
          this.type = 5;
        }
        if (this.$route.query.page) {
          this.currentPage = Number(this.$route.query.page);
        } else {
          this.currentPage = 1;
        }
        this.typeEvent();
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  created() {},

  methods: {
    // 不同类型对应不同事件
    typeEvent() {
      if (this.type == 1) {
        this.searchKey = this.$route.query.keyword;
        this.metaTitle = this.searchKey;
        this.searchValue = "search:" + this.searchKey;
        this.getList(this.searchKey);
      }
      if (this.type == 2) {
        let name = this.$route.query.type;
        this.metaTitle = name;
        this.typeKey = this.$route.params.id;
        this.searchValue = `List of ${name} Best Novel`;
        this.getList(this.typeKey);
      }
      if (this.type == 3) {
        let name = this.$route.params.name;
        let key = name.replace(/-/g, " ");
        this.metaTitle = key;
        this.searchValue = `Author: ${key}`;
        this.getList(key);
      }
      if (this.type == 4) {
        let key = this.$route.params.key;
        this.metaTitle = key;
        this.searchValue = `Status: ${key}`;
        this.getList(key);
      }
      if (this.type == 5) {
        let name = this.$route.params.name;
        this.searchValue = name.replace(/-/g, " ");
        this.metaTitle = this.searchValue;
        if (name == "Latest-Release") {
          this.navListKey = 1;
          this.getNewList(); //最新
        }
        if (name == "Hot-Novel") {
          this.navListKey = 2;
          this.getHotList(); // 热门
        }
        if (name == "Completed-Novel") {
          this.navListKey = 3;
          this.getCompleteList(); // 完成
        }
      }
    },
    // 获取数据
    getList(key) {
      fictionSearch(this.currentPage, 20, this.type, key).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.data || [];
          this.total = res.data.total || 0;
          this.endPage = this.total > 0 ? Math.ceil(this.total / 20) : 0;
        }
      });
    },
    //热门小说
    getHotList() {
      fictionHot(this.currentPage, 20, 0).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.data;
          this.total = res.data.total || 0;
          this.endPage = this.total > 0 ? Math.ceil(this.total / 20) : 0;
        }
      });
    },
    // 最新小说
    getNewList() {
      fictionLatest(this.currentPage, 20, 0).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.data;
          this.total = res.data.total || 0;
          this.endPage = this.total > 0 ? Math.ceil(this.total / 20) : 0;
        }
      });
    },
    // //完成小说
    getCompleteList() {
      fictionCompleted(this.currentPage, 20).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.data;
          this.total = res.data.total || 0;
          this.endPage = this.total > 0 ? Math.ceil(this.total / 20) : 0;
        }
      });
    },
    // 不同类型路由跳转处理
    pageFilter(currentPage, pageType) {
      let url = this.$route.path;
      // let fullUrl = window.location.href; //获取当前url
      if (this.type == 1) {
        if (pageType == 2) {
          this.$router.push({
            path: url,
            query: { keyword: this.searchKey, page: currentPage },
          });
        } else {
          this.$router.push({ path: url, query: { keyword: this.searchKey } });
        }
      } else if (this.type == 2) {
        if (pageType == 2) {
          this.$router.push({
            path: url,
            query: {
              type: this.$route.query.type,
              page: currentPage,
            },
          });
        } else {
          this.$router.push({
            path: url,
            query: { type: this.$route.query.type },
          });
        }
      } else {
        if (pageType == 2) {
          this.$router.push({ path: url, query: { page: currentPage } });
        } else {
          this.$router.push(url);
        }
      }

      // this.reload();
    },
    // 切换页码
    changePage(value) {
      if (value == 1) {
        this.currentPage = 1;
        this.pageFilter(this.currentPage, 1);
      } else if (value == this.endPage) {
        this.currentPage = this.endPage;
        this.pageFilter(this.currentPage, 2);
      } else {
        this.currentPage = value;
        this.pageFilter(this.currentPage, 2);
      }
    },
    // 去首页
    goFirst() {
      if (this.currentPage !== 1) {
        this.currentPage = 1;
        this.pageFilter(this.currentPage, 1);
      }

      // this.$router.push()
      // if (this.type == 5) {
      //   this.$router.push(`/list/${this.key}`);
      // } else if (this.type == 1) {
      //   this.$router.push({ path: "/search", query: { keyword: this.key } });
      // } else if (this.type == 2) {
      //   this.$router.push({
      //     path: `/genre/${this.key}`,
      //     query: { type: name },
      //   });
      // } else if (this.type == 3) {
      // } else if (this.type == 4) {
      // }
    },
    // 去最后一页
    goEnd() {
      if (this.currentPage !== this.endPage) {
        this.currentPage = this.endPage;
        this.pageFilter(this.currentPage, 2);
      }
    },
    // 搜索类别
    goType(key, name) {
      if (this.navListKey != key) {
        this.currentPage = 1;
        this.navListKey = key;
        name = name.replace(/\s/g, "-");
        this.$router.push(`/list/${name}`);
        this.reload();
      } else {
        this.$refs.nav.clearSelect();
      }
    },
    // 搜索类别
    goTypeList(key, type, name) {
      if (this.typeKey != key) {
        this.currentPage = 1;
        this.typeKey = key;
        name = name.replace(/\s/g, "-");
        this.$router.push({
          path: `/genre/${key}`,
          query: { type: name },
        });
        this.reload();
      } else {
        this.$refs.nav.clearSelect();
      }
    },
    // 输入框搜索
    goSearchList(value) {
      if (this.searchKey !== value) {
        this.currentPage = 1;
        this.searchKey = value;
        this.$router.push({ path: "/search", query: { keyword: value } });
        this.reload();
      } else {
        this.$refs.nav.clearSelect();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list_container {
  .page_box {
    display: flex;
    margin: 20px 0;
    justify-content: center;

    .first {
      line-height: 20px;
      border: 1px solid #ddd;
      border-right: none;
      padding: 5px 10px;
    }

    .end {
      line-height: 20px;
      border: 1px solid #ddd;
      padding: 5px 10px;
      border-left: none;
    }

    .page_class {
      &::v-deep .van-pagination__item {
        height: 32px;
        width: 27px;
        padding: 5px 10px;
        color: #4e4e4e;
        background: #f2f2f2;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
      }

      &::v-deep .van-pagination__item--active {
        background-color: #34495e;
        border-top: #34495e;
        border-bottom: #34495e;
        color: #fff;
      }
      &::v-deep .van-pagination__item--disabled,
      &::v-deep .van-pagination__item--disabled:active {
        opacity: 1;
        background: #ffffff !important;
      }

      &::v-deep .van-pagination__page {
        flex-grow: 1;
      }
    }
    .active_white {
      background-color: #fff;
    }
  }
}
</style>