<template>
  <div class="list_container" @click.stop="closeSelect">
    <new-nav
      ref="nav"
      :page="'List'"
      :url="searchValue"
      :typeList="typeList"
      @onSearch="goSearchList"
      @onNavType="goTypeList"
      @onType="goLeftType"
    ></new-nav>
    <div class="container dis_f">
      <div class="content_l">
        <list-template
          :type="'one'"
          :data="dataList"
          :searchValue="searchValue"
        ></list-template>

        <!-- 页码 -->
        <div class="page_box" v-if="total && total > 0">
          <div
            class="first"
            :class="{ active_white: currentPage == 1 }"
            @click="goFirst"
          >
            « First
          </div>
          <van-pagination
            v-model="currentPage"
            :total-items="total"
            :items-per-page="pageNum"
            :show-page-size="pageSize"
            class="page_class"
            @change="changePage(currentPage)"
          >
            <template #prev-text>
              <van-icon name="arrow-left" />
            </template>
            <template #next-text>
              <van-icon name="arrow" />
            </template>
          </van-pagination>
          <div
            class="end"
            :class="{ active_white: currentPage == endPage }"
            @click="goEnd"
          >
            List »
          </div>
        </div>
      </div>
      <div class="content_r">
        <list-template
          :type="'four'"
          :data="typeList"
          :borderShow="true"
          :searchValue="'Genres'"
          @onTypeList="goTypeList"
          class="m-b"
        ></list-template>
        <list-template
          :type="'three'"
          :data="hotList"
          :searchValue="'Hot Harem Novel'"
          @onTypeList="goTypeList"
        ></list-template>
      </div>
    </div>
    <new-footer></new-footer>
  </div>
</template>

<script>
import {
  fictionLabel,
  fictionHot,
  fictionLatest,
  fictionCompleted,
  fictionSearch,
} from "@/api/index.js";
import NewNav from "@/components/pc/NewNav.vue";
import ListTemplate from "@/components/pc/ListTemplate.vue";
import NewFooter from "@/components/pc/NewFooter.vue";

export default {
  components: {
    NewNav,
    ListTemplate,
    NewFooter,
  },
  inject: ["reload"],
  name: "List",
  metaInfo() {
    return {
      title: this.metaTitle + " | Webnovelsite",
      meta: [
        {
          name: "title",
          content: this.metaTitle + " | Webnovelsite",
        },
        {
          name: "description",
          content:
            "Webnovelsite is the foremost English  publisher of Chinese, Korean, Japanese and  English  webnovels and light novels. All thousands of novels are free and updated daily.",
        },
        {
          name: "keywords",
          content:
            "best webnovel, free webnovels, free books, novel updates, webnovel site, best light novels, webnovelsite",
        },
      ],
    };
  },
  data() {
    return {
      dataList: [],
      hotList: [], //热门小说
      typeList: [], //小说类型
      searchValue: "",
      typeKey: null, //类型关键id
      searchKey: null, //搜索关键字
      navListKey: null, //导航条最左侧列表类型id
      type: null, //1搜书名 2搜小说类型 3搜索作者 4小说是否完成 5固定类型
      currentPage: 1, //当前页
      endPage: null, //最后一页
      pageSize: 7, //页码数量显示
      total: null, //总页数
      pageNum: 20, //一页显示数量
      metaTitle: "", //标题头
    };
  },
  watch: {
    "$route.path": {
      handler(toPath, fromPath) {
        if (toPath !== fromPath) {
          if (toPath == "/search") {
            this.type = 1;
          } else if (toPath.indexOf("/genre") !== -1) {
            this.type = 2;
          } else if (toPath.indexOf("/author") !== -1) {
            this.type = 3;
          } else if (toPath.indexOf("/status") !== -1) {
            this.type = 4;
          } else if (toPath.indexOf("/list") !== -1) {
            this.type = 5;
          }
          if (this.$route.query.page) {
            this.currentPage = Number(this.$route.query.page);
          } else {
            this.currentPage = 1;
          }
          this.typeEvent();
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  created() {
    let typeList = this.$local.cacheGet("typeList");
    if (!typeList) {
      this.getTypeList();
    } else {
      this.typeList = typeList;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },

  methods: {
    // 不同类型对应不同事件
    typeEvent() {
      if (this.type == 1) {
        this.searchKey = this.$route.query.keyword;
        this.metaTitle = this.searchKey;
        this.searchValue = "search:" + this.searchKey;
        this.getList(this.searchKey);
      }
      if (this.type == 2) {
        let name = this.$route.query.type;
        this.metaTitle = name;
        this.typeKey = this.$route.params.id;
        this.searchValue = `List of ${name} Best Novel`;
        this.getList(this.typeKey);
      }
      if (this.type == 3) {
        let name = this.$route.params.name;
        let key = name.replace(/-/g, " ");
        this.metaTitle = key;
        this.searchValue = `Author: ${key}`;
        this.getList(key);
      }
      if (this.type == 4) {
        let key = this.$route.params.key;
        this.metaTitle = key;
        this.searchValue = `Status: ${key}`;
        this.getList(key);
      }
      if (this.type == 5) {
        let name = this.$route.params.name;
        this.searchValue = name.replace(/-/g, " ");
        this.metaTitle = this.searchValue;
        if (name == "Latest-Release") {
          this.navListKey = 1;
          this.getNewList(); //最新
        }
        if (name == "Hot-Novel") {
          this.navListKey = 2;
          if (this.currentPage == 1) {
            this.getHot(1, 20); // 热门
          } else {
            this.getHotList(); // 热门
          }
        }
        if (name == "Completed-Novel") {
          this.navListKey = 3;
          this.getCompleteList(); // 完成
        }
      }
      if (
        (this.type == 5 && this.navListKey !== 2) ||
        this.type !== 5 ||
        (this.type == 5 && this.navListKey == 2 && this.currentPage !== 1)
      ) {
        this.getHot(null, 10);
      }
    },
    // 小说类型
    getTypeList() {
      fictionLabel().then((res) => {
        if (res.code == 200) {
          this.typeList = res.data;
          let time = 24 * 60 * 60;
          this.$local.cacheSet("typeList", res.data, time);
        }
      });
    },
    // 获取数据
    getList(key) {
      fictionSearch(this.currentPage, 20, this.type, key).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.data || [];
          this.total = res.data.total || 0;
          this.endPage = this.total > 0 ? Math.ceil(this.total / 20) : 0;
        }
      });
    }, //热门小说
    getHotList() {
      fictionHot(this.currentPage, 20, 0).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.data;
          this.total = res.data.total || 0;
          this.endPage = this.total > 0 ? Math.ceil(this.total / 20) : 0;
        }
      });
    },
    //右侧热门小说
    getHot(value, limit) {
      fictionHot(1, limit, 0).then((res) => {
        if (res.code == 200) {
          if (value) {
            this.dataList = res.data.data;
            this.total = res.data.total || 0;
            this.endPage = this.total > 0 ? Math.ceil(this.total / 20) : 0;
          }
          if (limit == 10) {
            this.hotList = res.data.data;
          } else {
            this.hotList = res.data.data.slice(0, 10);
          }
        }
      });
    },
    // 最新小说
    getNewList() {
      fictionLatest(this.currentPage, 20, 0).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.data;
          this.total = res.data.total || 0;
          this.endPage = this.total > 0 ? Math.ceil(this.total / 20) : 0;
        }
      });
    },
    // //完成小说
    getCompleteList() {
      fictionCompleted(this.currentPage, 20).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.data;
          this.total = res.data.total || 0;
          this.endPage = this.total > 0 ? Math.ceil(this.total / 20) : 0;
        }
      });
    },
    // 页面滚动
    scrollToTop() {
      if (this.showSelect) {
        this.showSelect = false;
      }
    },
    // 关闭选择框
    closeSelect() {
      this.$refs.nav.clearSelect();
    },
    // 搜索热门类别
    goLeftType(key, name) {
      if (this.navListKey != key) {
        this.currentPage = 1;
        this.navListKey = key;
        name = name.replace(/\s/g, "-");
        this.$router.push(`/list/${name}`);
        this.reload();
      }
    },
    // 搜索小说类别
    goTypeList(key, type, name) {
      if (this.typeKey != key) {
        this.currentPage = 1;
        this.typeKey = key;
        name = name.replace(/\s/g, "-");
        this.$router.push({
          path: `/genre/${key}`,
          query: { type: name },
        });
        this.reload();
      }
    },
    // 输入框搜索
    goSearchList(value) {
      if (this.searchKey !== value) {
        this.currentPage = 1;
        this.searchKey = value;
        this.$router.push({ path: "/search", query: { keyword: value } });
        this.reload();
      }
    },
    // 不同类型路由跳转处理
    pageFilter(currentPage, pageType) {
      let url = this.$route.path;
      // let fullUrl = window.location.href; //获取当前url
      if (this.type == 1) {
        if (pageType == 2) {
          this.$router.push({
            path: url,
            query: { keyword: this.searchKey, page: currentPage },
          });
        } else {
          this.$router.push({ path: url, query: { keyword: this.searchKey } });
        }
      } else if (this.type == 2) {
        if (pageType == 2) {
          this.$router.push({
            path: url,
            query: {
              type: this.$route.query.type,
              page: currentPage,
            },
          });
        } else {
          this.$router.push({
            path: url,
            query: { type: this.$route.query.type },
          });
        }
      } else {
        if (pageType == 2) {
          this.$router.push({ path: url, query: { page: currentPage } });
        } else {
          this.$router.push(url);
        }
      }

      this.reload();
    },
    // 切换页码
    changePage(value) {
      if (value == 1) {
        this.currentPage = 1;
        this.pageFilter(this.currentPage, 1);
      } else if (value == this.endPage) {
        this.currentPage = this.endPage;
        this.pageFilter(this.currentPage, 2);
      } else {
        this.currentPage = value;
        this.pageFilter(this.currentPage, 2);
      }
    },

    // 去首页
    goFirst() {
      if (this.currentPage !== 1) {
        this.currentPage = 1;
        this.pageFilter(this.currentPage, 1);
      }
    },

    // 去最后一页
    goEnd() {
      if (this.currentPage !== this.endPage) {
        this.currentPage = this.endPage;
        this.pageFilter(this.currentPage, 2);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list_container {
  .dis_f {
    display: flex;
    justify-content: space-between;
    .content_l {
      flex: 1;
      .page_box {
        cursor: pointer;
        display: flex;
        margin: 30px 0 38px;
        justify-content: center;

        .first {
          line-height: 20px;
          border: 1px solid #ddd;
          border-right: none;
          padding: 4px 10px;
        }

        .end {
          line-height: 20px;
          border: 1px solid #ddd;
          padding: 4px 10px;
          border-left: none;
        }

        .page_class {
          &::v-deep .van-pagination__item {
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            height: 32px;
            width: 27px;
            padding: 5px 10px;
            color: #4e4e4e;
            background: #f2f2f2;
          }

          &::v-deep .van-pagination__item--active {
            background-color: #34495e;
            border-top: #34495e;
            border-bottom: #34495e;
            color: #fff;
          }

          &::v-deep .van-pagination__item--disabled,
          &::v-deep .van-pagination__item--disabled:active {
            opacity: 1;
            background: #ffffff !important;
          }

          &::v-deep .van-pagination__page {
            flex-grow: 1;
          }
        }

        .active_white {
          background-color: #fff;
        }
      }
    }
    .content_r {
      width: 300px;
      margin-left: 7.5px;
    }
  }
  .m-b {
    margin-bottom: 15px;
  }
}
</style>